import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterLink} from '@angular/router';
import {distinctUntilChanged, filter} from "rxjs";
import {NgClass} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {MatListItem} from "@angular/material/list";
import {MatButtonModule} from "@angular/material/button";
import {IBreadcrumbItem} from "@shared/models/breadcrumb-item";

@Component({
  selector: 'app-bread-crumb',
  standalone: true,
  imports: [
    RouterLink,
    NgClass,
    MatIcon,
    MatListItem,
    MatButtonModule,
  ],
  templateUrl: './bread-crumb.component.html',
  styleUrl: './bread-crumb.component.scss'
})
export class BreadCrumbComponent implements OnInit {
  breadcrumbItems: IBreadcrumbItem[] = [];
  showBreadcrumb = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.updateBreadcrumb(this.router.url);

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe((event: NavigationEnd) => {
        this.updateBreadcrumb(event.url);
      });
  }

  private updateBreadcrumb(url: string) {
    this.breadcrumbVisibilityCheck(url);
    this.breadcrumbItems = this.buildBreadcrumbs(this.activatedRoute.root);
  }

  private buildBreadcrumbs(route: ActivatedRoute, url = '', breadcrumbs: IBreadcrumbItem[] = []): IBreadcrumbItem[] {
    const {data, path} = route.routeConfig || {};
    const label = data?.['breadcrumb'] || '';
    const currentPath = path || '';
    const nextUrl = currentPath ? `${url}/${currentPath}` : url;
    if (label) {
      breadcrumbs.push({label, url: nextUrl});
    }
    return route.firstChild ? this.buildBreadcrumbs(route.firstChild, nextUrl, breadcrumbs) : breadcrumbs;
  }

  breadcrumbVisibilityCheck(routeAddress: string) {
    this.showBreadcrumb = !(routeAddress.includes('dashboard'));
  }
}
