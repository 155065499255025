import { Component, inject } from '@angular/core';
import { NgOptimizedImage } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { AuthService } from '@core/services/auth.service';

@Component({
    selector: 'app-header',
    imports: [
        NgOptimizedImage,
        MatToolbarModule,
        MatIcon,
        MatButtonModule
    ],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss'
})
export class HeaderComponent {
  private _authService = inject(AuthService);


  logout() {
    this._authService.logout()
  }
}
