@if (showBreadcrumb) {
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li>
        <button mat-icon-button aria-label="Route to dashboard" [routerLink]="['/dashboard']">
          <mat-icon class="material-symbols-outlined">home</mat-icon>
        </button>
      </li>
      <li>
        <mat-icon class="outline-color">navigate_next</mat-icon>
      </li>
        @for (item of breadcrumbItems; track item; let last = $last) {
          <li>
            @if (last) {
              <span class="breadcrumb-last-item">{{ item.label }}</span>
            } @else {
              <a class="outline-color" [routerLink]="item.url">{{ item.label }}</a>
              <mat-icon class="outline-color">navigate_next</mat-icon>
            }
          </li>
        }
    </ol>
  </nav>
}
