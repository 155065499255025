import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-loading',
    imports: [],
    templateUrl: './loading.component.html',
    styleUrl: './loading.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class LoadingComponent {
  constructor() { }

}
