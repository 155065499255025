<header>
  <mat-toolbar role="banner">
    <img class="logo" ngSrc="images/icons/coiniGo-header-logo.svg" alt="CoiniGo company logo" height="33" width="110">

    <div class="header-actions">
<!--      <button mat-icon-button aria-label="View notifications">-->
<!--        <mat-icon class="material-icons-outlined">notifications</mat-icon>-->
<!--      </button>-->
      <button mat-icon-button aria-label="View profile">
        <mat-icon class="material-icons-outlined">person</mat-icon>
      </button>
      <button mat-icon-button aria-label="Log out" (click)="logout()">
        <mat-icon class="material-icons-outlined">logout</mat-icon>
      </button>
    </div>
  </mat-toolbar>
</header>
