import { MenuItem } from "./menu.model";
import {environment} from "@env/environment";

export const MENU_ITEMS: MenuItem[] = [
    {
        name: 'Dashboard', route: '/dashboard', children: [], icon: 'grid_view'
    },
    {
        name: 'Wallets', route: '/wallets', children: [], icon: 'account_balance_wallet'
    },
    {
        name: 'Transaction', children: [
            {
                name: 'Pay-In', route: '/transaction-history/pay-in', children: [], icon: ''
            },
            {
                name: 'Pay-Out', route: '/transaction-history/pay-out', children: [], icon: ''
            }
        ], icon: 'receipt_long'
    },
    {
        name: 'Settings', icon: 'settings', children: [
            {
                name: 'Profile', route: '/settings/profile', icon: '', children: []
            },
            {
                name: 'Security', route: '/settings/security', icon: '', children: []
            },
            // {
            //     name: 'Notifications', route: '/settings/notifications', icon: '', children: []
            // },
        ]
    },
    {
        name: 'Support', route: '/support', children: [], icon: 'support_agent'
    },
  {
    name: 'Documentation', icon: 'data_object', children: [
      {
        name: 'Introduction to CoiniGo', icon: '',localFileAddress: 'CoiniGo.pdf', children: []
      },
      {
        name: 'API Documentation', icon: '', url: `${environment.ipgUrl}api/docs`, children: []
      },
      {
        name: 'Webhook', icon: '', url: `${environment.ipgUrl}swagger/index.html`, children: []
      },
    ]
  },
]
