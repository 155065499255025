<mat-nav-list [ngClass]="{'list-is-open': expandedElement === menu()}">
    <a (isActiveChange)="isActiveChange()" mat-list-item
        [routerLink]="!menu().route ? null : menu().route"
        disableRipple="true" [style.padding-left]="index() + 8 + 'px'" routerLinkActive="item-selected"
        aria-current="page" (click)="changeMenuState()">
        <span class="menu-icon">
            <mat-icon class="material-symbols-outlined" [ngClass]="!menu().icon ? 'no-icon' : ''">{{menu().icon}}</mat-icon>
            {{menu().name}}
        </span>
        @if(menu().children.length){
        <span class="chevron">
            <mat-icon [ngClass]="{'rotate': expandedElement === menu()}">
                arrow_drop_down
            </mat-icon>
        </span>
        }
    </a>
    @for (children of menu().children; track children.route ? children.route : children) {
      <mat-nav-list [@bodyExpansion]="expandedElement === menu() ? 'expanded' : 'collapsed'">
        <app-nav-item [menu]="children" [index]="index() - 5" [parentMenu]="menu()"
                      [parentMenuIsOpen]="expandedElement === menu()" />
      </mat-nav-list>
    }
</mat-nav-list>
