import { HttpErrorResponse, HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { AlertService } from '@shared/services/alert.service';
import { AuthService } from '@core/services/auth.service';
import { catchError, tap, throwError } from 'rxjs';

export const errorHandlingInterceptor: HttpInterceptorFn = (req, next) => {
  const alertService = inject(AlertService)
  const authService = inject(AuthService)
  return next(req).pipe(
    tap((event: any) => {
      if (event.status == 200 && event.body && !event.body.successed) {
        const messages = event.body.messages as []
        messages && messages.forEach((message) => {
          alertService.failedAlert(message)
          return throwError(() => message)
        })
      }
    }),
    catchError(
      (error: HttpErrorResponse) => {
        switch (error.status) {
          case 400:
            errorHandling(error, alertService)
            return throwError(() => error)

          case 401:
            if (req.url.includes('api/idp/token/authorize')) {
              authService.clearTokens();
              return throwError(() => error)
            }

            authService.clearTokens();
            errorHandling(error, alertService, 'unauthorized')
            return throwError(() => error)

          case 403:
            errorHandling(error, alertService, 'Illegal access')
            return throwError(() => error)

          case 404:
            errorHandling(error, alertService)
            return throwError(() => error)

          case 500:
            errorHandling(error, alertService)
            return throwError(() => error)
        }


        return throwError(() => error)
      }))

};

const errorHandling = (error: HttpErrorResponse, alertService: AlertService, text: string = 'Operation Failed') => {
  const messages = (error.error.messages ?? null) as any[] | null

  if (messages === null) {
    alertService.failedAlert(text)
    return
  }

  messages.forEach((message) => {
    alertService.failedAlert(message.description ?? text)
  })
}

