import { inject } from '@angular/core';
import { HttpInterceptorFn } from '@angular/common/http';
import { AuthService } from '../services/auth.service';

export const JwtInterceptor: HttpInterceptorFn = (request, next) => {
  const authService = inject(AuthService)
  const token = authService.token;


  if (token) {
    const authRequest = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });

    return next(authRequest);
  }

  return next(request);
}

