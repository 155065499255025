import { Routes } from '@angular/router';
import { authLoginGuard } from '@core/guards/auth.guard';
import { MainLayoutComponent } from '@shared/layout/main-layout/main-layout.component';

export const routes: Routes = [
    {
        path: '', component: MainLayoutComponent,
        children: [
            {
                path: '', redirectTo: 'dashboard', pathMatch: "full"
            },
            {
                path: 'dashboard',
                loadChildren: () => import('./pages/dashboard/dashboard.routes'),
                canActivate: [authLoginGuard]
            },
            {
                path: 'wallets',
                loadChildren: () => import('./pages/wallets/wallets.routes'),
                data: {
                    breadcrumb: ''
                },
                canActivate: [authLoginGuard]
            },
            {
                path: 'transaction-history', loadChildren: () => import('./pages/transactions/transactions.routes'),
                data: {
                    breadcrumb: ''
                },
                canActivate: [authLoginGuard]
            },
            {
              path: 'settings', loadChildren: () => import('./pages/setting/setting.routes'),
              data: {
                breadcrumb: ''
              },
              canActivate: [authLoginGuard]
            },
            {
              path: 'support', loadChildren: () => import('./pages/support/support.routes'),
              data: {
              breadcrumb: ''
              },
              canActivate: [authLoginGuard]
            },
            {
              path: 'wallets', loadChildren: () => import('./pages/wallets/wallets.routes'),
              data: {
                breadcrumb: ''
                },
              canActivate: [authLoginGuard]
            },

        ]
    },
    {
        path: 'auth', loadChildren: () => import('./pages/auth/auth.routes')
    },
    {
        path: '**', redirectTo: '/'
    }
];
