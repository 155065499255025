import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@core/services/auth.service';

export const authLoginGuard: CanActivateFn = (route, state): boolean => {
    const authService = inject(AuthService)
    if (authService.isUserLogin()) {
        return true;
    }

    const router: Router = inject(Router)
    router.navigate(['auth'], { queryParams: { returnUrl: state.url } })
    return false;

}; 
