@if (isUserLogin) {
<app-header></app-header>
<div class="layout-body">
    <section class="side-menu">
        <app-nav />
    </section>
    <section class="outlet">
        <div>
            <app-bread-crumb></app-bread-crumb>
        </div>
        <router-outlet></router-outlet>
    </section>
</div>
}
@else {
<app-loading />
}