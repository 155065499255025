import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideToastr } from 'ngx-toastr';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { JwtInterceptor } from '@core/interceptors/jwt.interceptor';
import { loadingInterceptor } from '@core/interceptors/loading.interceptor';
import { errorHandlingInterceptor } from '@core/interceptors/error-handling.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [provideZoneChangeDetection({ eventCoalescing: true }), provideRouter(routes), provideAnimationsAsync(),
  provideToastr(), provideHttpClient(withInterceptors([JwtInterceptor, loadingInterceptor, errorHandlingInterceptor]))
  ]
};
