import { Component, effect, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { BreadCrumbComponent } from '@shared/components/bread-crumb/bread-crumb.component';
import { NavComponent } from '@shared/components/nav-list/nav.component';
import { LoadingComponent } from '@shared/components/loading/loading.component';
import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, BreadCrumbComponent, NavComponent, LoadingComponent],
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss'
})
export class MainLayoutComponent implements OnDestroy {
  isUserLogin: boolean = false;

  constructor(private _authService: AuthService) { }

  authenticated = effect(() => {
    this.isUserLogin = this._authService.isUserLoginSig()
  });

  ngOnDestroy(): void {
    this.authenticated && this.authenticated.destroy()
  }
}
