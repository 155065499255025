import { Component, ViewEncapsulation } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MENU_ITEMS } from '@shared/models/menu-items.model';
import { NavItemComponent } from '../nav-item/nav-item.component';
import {MatIcon} from "@angular/material/icon";


@Component({
  selector: 'app-nav',
  standalone: true,
  imports: [
    MatListModule,
    RouterLinkActive,
    RouterLink,
    NavItemComponent,
    MatIcon,
    ],
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class NavComponent {
  menuItems = MENU_ITEMS;
}
