import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgClass } from '@angular/common';
import {Component, OnChanges, OnDestroy, Optional, SimpleChanges, SkipSelf, ViewEncapsulation, effect, input} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MenuItem } from '@shared/models/menu.model';
import { MenuService } from '@shared/services/menu.service';
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-nav-item',
  standalone: true,
  animations: [
    trigger('bodyExpansion', [
      state('collapsed', style({ height: '0px', display: 'none' })),
      state('expanded', style({ height: '*', display: 'block' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
    ]),
  ],
  imports: [
    NgClass,
    MatListModule,
    RouterLinkActive,
    RouterLink,
    MatIconModule
  ],
  templateUrl: './nav-item.component.html',
  styleUrl: './nav-item.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class NavItemComponent implements OnChanges, OnDestroy {
  menu = input.required<MenuItem>()
  parentMenu = input.required<MenuItem | null>()
  parentMenuIsOpen = input.required<boolean>()
  index = input.required<number>()
  expandedElement: MenuItem | null = null;
  isFirstRun = true

  menuChangesSig = effect(() => {
    const state = this._menuService.expandedMenuElementSig();
    if (state && state.parentMenu === this.parentMenu() && state.menu !== this.menu()) {
      this.expandedElement = null;

      return
    }

  })

  constructor(@Optional() @SkipSelf() private navItemComponent: NavItemComponent, private _menuService: MenuService, private http: HttpClient) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['parentMenuIsOpen'] && !changes['parentMenuIsOpen'].currentValue) {
      this.expandedElement = null;

    }
  }

  ngOnDestroy(): void {
    this.menuChangesSig && this.menuChangesSig.destroy()
  }

  isActiveChange() {

    if (this.navItemComponent && this.isFirstRun) {
      this.navItemComponent.expandedElement = this.navItemComponent.menu();
      this.navItemComponent.isActiveChange();
      this.isFirstRun = this.navItemComponent.isFirstRun = false
    }

  }

  openFileInNewTab(){
    if (this.menu().localFileAddress) {
      window.open(`files/${this.menu().localFileAddress}`, '_blank');
      return;
    }
  }

  openUrlInNewTab(){
    if (this.menu().url) {
      window.open(this.menu().url, '_blank');
      return
    }
  }

  changeMenuState() {
    this.openFileInNewTab();
    this.openUrlInNewTab();

    if (this.menu().children.length) {

      if (this.expandedElement === this.menu()) {
        this.expandedElement = null;

        return
      }

      this.expandedElement = this.menu();
      this._menuService.updateMenuState({ menu: this.menu(), parentMenu: this.parentMenu() });

    }
  }
}
